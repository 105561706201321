import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { IonButton } from '@ionic/react';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { ButtonPropsBase } from 'components/common/button/Button';
import { ROUTES } from 'constants/routes';
import { HEADER_HPADDING } from 'styles/helpers/spacing';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import { matchPath } from 'react-router-dom';
import { Appointment, IModule, IPayableTool, IRelation, ITest } from 'types/app';
import Slider from 'components/slider/Slider';
import { SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { SliderContainer } from 'pages/relations/identification/RelationIdentification.styled';
import { useLocation } from 'react-router';
import useAPIRelations from 'hooks/api/useAPIRelations';
import { backPropsDefault } from 'pages/relations/Relations';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { RelationsAppointmentWrapper } from 'pages/relations/appointment/RelationAppointment.styled';
import RelationAppointmentHome from 'pages/relations/appointment/slides/relation-appointment-home/RelationAppointmentHome';
import RelationAppointmentColorSelection from 'pages/relations/appointment/slides/relation-appointment-color-selection/RelationAppointmentColorSelection';
import {
  MODULES_CODES,
  MODULES_TEST_IDS,
  MODULE_TEST_NAMES,
  NOTIFICATIONS,
} from 'constants/global';
import { ModuleTestContext, ModuleTestContextProps } from 'contexts/ModuleTestContext';
import useAPITest from 'hooks/api/useAPITest';
import { ModuleContext, ModuleContextProps } from 'contexts/ModuleContext';
import { getLocaleValue } from 'helpers/utils';
import { BuyToolContext, BuyToolContextProps } from 'contexts/BuyToolContext';
import useIonNavContext from 'hooks/useIonNavContext';

export interface RelationAppointmentProps {
  appointment?: Appointment.IAppointment;
  onSelectColors?: (userColorId: number, relationColorId: number) => void;
  gotoColorSelection?: () => void;
  relation?: IRelation;
}

const RelationAppointment: React.FC = () => {
  const { t, i18n } = useTranslation('translation');
  const { user } = useContext(UserContext) as UserContextProps;
  const { getRelationById } = useContext(RelationsContext) as RelationsContextProps;
  const { getAppointment, updateAppointment } = useAPIRelations();
  const swiperInstance = useRef<SwiperClass>();
  const { relationAppointmentTest, hasRelationAppointmentTest, loadTestInfos, testInfos } =
    useContext(ModuleTestContext) as ModuleTestContextProps;
  const { addToolToShoppingList, formatModuleToPayableTool } = useContext(
    BuyToolContext,
  ) as BuyToolContextProps;
  const { gotoUrl } = useIonNavContext();

  const { initModuleTest } = useAPITest();

  // Start trick
  /*
   * useParams failed sometimes
   * */
  const location = useLocation();
  const match = matchPath(location.pathname || location.location.pathname, {
    path: `${ROUTES.RELATIONS.APPOINTMENT}/:idRelation`,
    exact: true,
  });
  // End trick

  // const currentRelation: IRelation | undefined = getRelationById(
  //   parseInt((match?.params as any)?.idRelation, 10),
  // );
  const [currentRelation, setCurrentRelation] = useState<IRelation>();

  useEffect(() => {
    setCurrentRelation(getRelationById(parseInt((match?.params as any)?.idRelation, 10)));
  }, []);

  const {
    icons: [chevronBack],
  } = useIcons([ICON_NAMES.CHEVRON_BACK]);
  const [backLinkProps, setBackLinkProps] = useState<ButtonPropsBase>(backPropsDefault);
  const [activeIndex, updateActiveIndex] = useState<number>(0);
  const [appointment, setAppointment] = useState<Appointment.IAppointment>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isTestRelationAppointmentValid, setIsTestRelationAppointmentValid] = useState<boolean>();
  const [currentRelationAppointment, setCurrentRelationAppointment] = useState<IModule>();
  const { modules } = useContext(ModuleContext) as ModuleContextProps;

  const loadAppointment = async (relation: IRelation) => {
    setLoading(true);
    user &&
      (user?.profileInfo?.notifications?.includes(NOTIFICATIONS.SALE) ||
        hasRelationAppointmentTest) &&
      setAppointment(await getAppointment(relation, user));
    setLoading(false);
  };

  // useEffect(() => {
  //   console.log(appointment);
  // }, [appointment]);

  const changeAppointment = async (
    relationSelectedColourId: number,
    userSelectedColourId: number,
  ) => {
    if (currentRelation && user) {
      const result = await updateAppointment(
        currentRelation,
        relationSelectedColourId,
        user,
        userSelectedColourId,
      );
      if (result) {
        setAppointment(result);
        setTimeout(() => {
          swiperInstance.current && swiperInstance.current.slideTo(0);
        }, 250);
      }
    }
  };

  // Swiper events
  const onInitSwiper = (swiper: SwiperClass) => {
    swiperInstance.current = swiper;
  };
  const onSlideChange = (swiper: SwiperClass) => {
    updateActiveIndex(swiper.activeIndex);
  };
  const gotoColorSelection = () => {
    swiperInstance.current && swiperInstance.current.slideTo(1);
  };

  // Handle colors selection
  const onSelectColors = (userColorId: number, relationColorId: number) => {
    console.log('CALL userColorId: ', userColorId, ', relationColorId: ', relationColorId);
    console.log(
      'appointment?.vendorInteraction?.colourId: ',
      appointment?.vendorInteraction?.colourId,
      ', appointment?.buyerStyle?.colourId: ',
      appointment?.buyerStyle?.colourId,
    );
    if (
      userColorId !== appointment?.vendorInteraction?.colourId ||
      relationColorId !== appointment?.buyerStyle?.colourId
    ) {
      changeAppointment(relationColorId, userColorId);
    } else {
      swiperInstance.current && swiperInstance.current.slideTo(0);
    }
  };

  // Handle back button action
  useEffect(() => {
    let backConf: ButtonPropsBase = backPropsDefault;
    if (activeIndex !== 0) {
      backConf = {
        onClick: () => {
          swiperInstance.current?.slideTo(0);
        },
      };
    }
    setBackLinkProps(backConf);
  }, [activeIndex]);

  useEffect(() => {
    currentRelation && loadAppointment(currentRelation);
  }, [currentRelation]);

  const onInitRelationAppointmentTest = async (relationId: number) => {
    if (user) {
      const init = await initModuleTest(
        user?.id,
        `${user.token?.type} ${user.token?.value}`,
        MODULES_TEST_IDS.RELATION_APPOINTMENT,
        relationId,
      );

      if (init) {
        await loadTestInfos(user);
      }
    }
  };

  const loadAppointmentAfterTestInit = async (relation: IRelation) => {
    user && currentRelation && setAppointment(await getAppointment(relation, user));
  };

  useEffect(() => {
    currentRelation && loadAppointmentAfterTestInit(currentRelation);
  }, [testInfos]);

  useEffect(() => {
    setCurrentRelationAppointment(
      modules.find((item) => item.code === MODULES_CODES.APPOINTMENT) as IModule,
    );
  }, [modules]);

  const checkValidRelationAppointmentTest = (test: ITest, relationToCheck: IRelation): boolean => {
    return (
      !!user?.profileInfo?.colors?.primary &&
      !user?.profileInfo?.notifications?.includes(NOTIFICATIONS.SALE) &&
      !test.expiredAt &&
      test.daysLeft > 0 &&
      getLocaleValue(test.themeName, i18n.language) ===
        getLocaleValue(MODULE_TEST_NAMES.RELATION_APPOINTMENT, i18n.language) &&
      test.relationId === relationToCheck.id
    );
  };

  useEffect(() => {
    if (relationAppointmentTest && currentRelation) {
      setIsTestRelationAppointmentValid(
        !!checkValidRelationAppointmentTest(relationAppointmentTest, currentRelation as IRelation),
      );
    }
  }, [currentRelation, relationAppointmentTest]);

  const onBuyRelationAppointmentModule = (module: IModule) => {
    addToolToShoppingList(formatModuleToPayableTool(module) as IPayableTool);
    gotoUrl(ROUTES.SHOP);
  };

  return (
    <PageLayout headerProps={{ title: t('common.relations') }}>
      <RelationsAppointmentWrapper className="app-page">
        <Container padding={HEADER_HPADDING}>
          <IonButton {...backLinkProps} className="clear">
            <div className="icon" slot="start">
              {chevronBack.icon}
            </div>
            <span className="label">{t('common.back')}</span>
          </IonButton>
        </Container>
        {!isLoading && currentRelation && (
          <>
            <Container>
              <PageInnerTitle
                dangerouslySetInnerHTML={{
                  __html: t('relations.appointment.title', {
                    relationFullName: `${currentRelation.fullName}`,
                  }),
                }}
              />
            </Container>
            <SliderContainer>
              <Slider onInit={onInitSwiper} allowTouchMove={false} onSlideChange={onSlideChange}>
                <SwiperSlide>
                  <RelationAppointmentHome
                    appointment={appointment}
                    relation={currentRelation}
                    gotoColorSelection={gotoColorSelection}
                    onInitRelationAppointmentTest={onInitRelationAppointmentTest}
                    isTestRelationAppointmentValid={isTestRelationAppointmentValid}
                    canLaunchRelationAppointmentTest={
                      !relationAppointmentTest && !hasRelationAppointmentTest
                    }
                    appointmentModule={currentRelationAppointment}
                    onBuyRelationAppointmentModule={onBuyRelationAppointmentModule}
                    hasRelationAppointmentTest={hasRelationAppointmentTest}
                    relationAppointmentTest={relationAppointmentTest}
                    hasAlreadyUsedTestOnRelation={
                      currentRelation &&
                      relationAppointmentTest &&
                      relationAppointmentTest.relationId == currentRelation.id
                    }
                  />
                </SwiperSlide>
                {appointment && (
                  <SwiperSlide>
                    <RelationAppointmentColorSelection
                      onSelectColors={onSelectColors}
                      relation={currentRelation}
                      appointment={appointment}
                    />
                  </SwiperSlide>
                )}
              </Slider>
            </SliderContainer>
          </>
        )}
      </RelationsAppointmentWrapper>
    </PageLayout>
  );
};

export default RelationAppointment;

import Button from 'components/common/button/Button';
import { AppModalTitle } from 'components/modal/AppModal.styled';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import { getLocaleValue } from 'helpers/utils';
import useAPIRelations from 'hooks/api/useAPIRelations';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, ILocale } from 'types/app';
import { AcceptInvitationModalWrapper } from 'components/relations/invitations/single-relation-invitation/accept-invitation-modal/AcceptInvitationModal.styled';
import SelectBox from 'components/common/form/selectbox/SelectBox';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface AcceptInvitationModalProps {
  title: ILocale;
  notificationId: number;
  onRefuseInvitation?: () => Promise<void>;
}

const AcceptInvitationModal: React.FC<AcceptInvitationModalProps> = ({
  title,
  notificationId,
  onRefuseInvitation,
}) => {
  const { t, i18n } = useTranslation('translation');
  const { groupsList } = useContext(RelationsContext) as RelationsContextProps;
  const { user } = useContext(UserContext) as UserContextProps;
  const { getRelations, acceptRelationInvitation } = useAPIRelations();
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { loadNotifications } = useAPIAdvices();

  const onAcceptInvitation = async (notificationId: number, groupId: number) => {
    const result = await acceptRelationInvitation(notificationId, groupId);
    result && getRelations();
    updateModalConf();
    await loadNotifications(AdviceType.ALL, user?.id as number);
  };

  const groupsOptions: Array<Form.IOption> = groupsList.map((group) => {
    return {
      id: group.id,
      label: group.name,
      value: `${group.id}`,
      onClick: async () => {
        await onAcceptInvitation(notificationId as number, group.id);
      },
    };
  });

  return (
    <AcceptInvitationModalWrapper>
      <AppModalTitle>{getLocaleValue(title, i18n.language)}</AppModalTitle>
      <center className="modal-description">
        <strong>Acceptez-vous de l&apos;ajouter à vos relations ?</strong>
      </center>
      <div className="confirmation-buttons">
        <div className="refuse-button confirmation-button">
          <Button
            fill="outline"
            color="white"
            onClick={async () => {
              onRefuseInvitation && (await onRefuseInvitation());
            }}
          >
            {t('common.refuse')}
          </Button>
        </div>
        <div className="accept-button confirmation-button">
          <SelectBox
            label=""
            name="test"
            value={{
              id: 0,
              label: t('common.yes'),
              value: '',
            }}
            options={groupsOptions}
          />
        </div>
      </div>
    </AcceptInvitationModalWrapper>
  );
};

export default AcceptInvitationModal;

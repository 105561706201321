import styled from 'styled-components';
import { IntroContainer } from 'pages/intro/Intro.styled';
import { prem } from 'styles/helpers/spacing';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { CheckboxLabel, CheckboxWrapper } from 'components/common/form/checkbox/Checkbox.styled';
import { BODY } from 'styles/helpers/typography';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';
import { IonInputWrapper, IonItemWrapper } from 'components/common/form/textfield/Textfield.styled';
import { SelectBoxWrapper } from 'components/common/form/selectbox/SelectBox.styled';

const RegisterFormWrapper = styled(IntroContainer)`
  padding-top: ${prem(20)};
  text-align: center;
  width: 90%;
  margin: 0 auto;

  ${IonInputWrapper} {
    ${IonItemWrapper} {
      ion-label {
        color: white !important;
      }

      ion-input {
        color: white;
      }

      &::part(native) {
        background: var(--comcolors-modal-background);
      }
    }

    @media (${MQ_MAX_HEIGHT}) {
      margin-bottom: ${prem(8)};
    }
  }

  ${IonButtonWrapper} {
    margin: ${prem(20)} auto;
    width: 50%;
    min-width: ${prem(190)};
    max-width: ${prem(250)};
  }

  .accept-cgu {
    margin-top: ${prem(40)};
  }

  .have-account {
    cursor: pointer;
  }

  ${SelectBoxWrapper} {
    * {
      color: white !important;

      &::part(native) {
        background: var(--comcolors-modal-background);
      }
    }
  }

  ${CheckboxWrapper} {
    ${CheckboxLabel} {
      ${BODY.small};
      color: white;

      a {
        color: white;
      }
    }
  }

  form {
    padding-bottom: ${prem(20)};
  }
`;

export { RegisterFormWrapper };

import React from 'react';
import { useTranslation } from 'react-i18next';
import IconProfile from 'components/common/icons/icon-profile/IconProfile';
import { ReactComponent as IconPhone } from 'assets/svg/icon-phone.svg';
import { ReactComponent as IconContact } from 'assets/svg/icon-comment.svg';
import { ReactComponent as IconFaq } from 'assets/svg/icon-faq.svg';
import { ReactComponent as IconModel } from 'assets/svg/icon-model.svg';
import { ReactComponent as IconPower } from 'assets/svg/icon-power.svg';
import { ReactComponent as IconSettings } from 'assets/svg/icon-settings.svg';
import { ReactComponent as IconTool } from 'assets/svg/icon-tools.svg';
import { ReactComponent as IconHome } from 'assets/svg/icon-home.svg';
import { ReactComponent as IconRelations } from 'assets/svg/icon-relations.svg';
import { ReactComponent as IconResources } from 'assets/svg/icon-resources.svg';
import { ReactComponent as IconMore } from 'assets/svg/icon-more.svg';
import { ReactComponent as IconProfileSvg } from 'assets/svg/icon-profile.svg';
import { ReactComponent as IconUnknownProfile } from 'assets/svg/icon-unknwon-profile.svg';
import { ReactComponent as IconNotification } from 'assets/svg/icon-notification.svg';
import { ReactComponent as IconShop } from 'assets/svg/icon-shop.svg';
import { ReactComponent as IconMessageSent } from 'assets/svg/icon-message-sent.svg';
import { ReactComponent as IconMessageInProgress } from 'assets/svg/icon-message-inprogress.svg';
import { ReactComponent as IconRelationAppointment } from 'assets/svg/icon-relation-appointment.svg';
import { ReactComponent as IconRelationMngt } from 'assets/svg/icon-relation-mngt.svg';
import { ReactComponent as IconProfileBadgeChecked } from 'assets/svg/icon-profile-badge-checked.svg';
import { ReactComponent as IconProfileBadgeQuestion } from 'assets/svg/icon-profile-badge-question.svg';
import { ReactComponent as IconProfileBadgeWarning } from 'assets/svg/icon-profile-badge-warning.svg';
import { ReactComponent as IconProfileUnspecified } from 'assets/svg/icon-profile-unspedcified.svg';
import { ReactComponent as IconChevronSvg } from 'assets/svg/icon-chevron.svg';
import { ReactComponent as IconChevronBackSvg } from 'assets/svg/icon-chevron-back.svg';
import { ReactComponent as IconRelationInteractionSvg } from 'assets/svg/icon-transaction.svg';
import { ReactComponent as IconRelationSubscriptionMarker } from 'assets/svg/icon-start-subscribe.svg';
import { ReactComponent as IconFavorite } from 'assets/svg/icon-heart.svg';
import { ReactComponent as IconMyProfile } from 'assets/svg/icon-profile-user-profile.svg';
import { ReactComponent as IconMySummary } from 'assets/svg/icon-profile-user-summary.svg';
import { ReactComponent as IconMyMotivation } from 'assets/svg/icon-profile-user-motivation.svg';
import { ReactComponent as IconColourProfile } from 'assets/svg/icon-profile-colour-profile.svg';
import { ReactComponent as IconRefresh } from 'assets/svg/icon-refresh.svg';
import { ReactComponent as IconLogo } from 'assets/svg/icon-logo.svg';
import { ReactComponent as IconSixColors } from 'assets/svg/icon-six-colors.svg';
import { ReactComponent as IconTestimonials } from 'assets/svg/icon-testimonials.svg';
import { ReactComponent as IconFranckJulien } from 'assets/svg/icon-franck-julien.svg';
import { ReactComponent as IconShare } from 'assets/svg/icon-share.svg';
import { ReactComponent as IconModules } from 'assets/svg/icon-modules.svg';
import { ReactComponent as IconBooks } from 'assets/svg/icon-books.svg';
import { ReactComponent as IconSuccess } from 'assets/svg/icon-sucess.svg';
import { ReactComponent as IconVideo } from 'assets/svg/icon-video.svg';
import { ReactComponent as IconSend } from 'assets/svg/icon-send.svg';
import { ReactComponent as IconYoutube } from 'assets/svg/icon-youtube.svg';
import { ReactComponent as IconVimeo } from 'assets/svg/icon-vimeo.svg';
import { ReactComponent as IconTiktok } from 'assets/svg/icon-tiktok.svg';
import { ReactComponent as IconLinkedin } from 'assets/svg/icon-linkedin.svg';
import { ReactComponent as IconMapDraw } from 'assets/svg/icon-map-draw.svg';
import { ReactComponent as IconElearning } from 'assets/svg/icon-elearning.svg';
import { ReactComponent as IconAdd } from 'assets/svg/icon-add.svg';
import { ReactComponent as IconMicrophone } from 'assets/svg/icon-microphone.svg';
import { ReactComponent as IconApplePodcast } from 'assets/svg/icon-apple-podcast.svg';
import { ReactComponent as IconSpotifyPodcast } from 'assets/svg/icon-spotify.svg';
import { ReactComponent as IconDeezerPodcast } from 'assets/svg/icon-deezer.svg';
import { ReactComponent as IconInfo } from 'assets/svg/icon-info.svg';
import { ReactComponent as IconTalent } from 'assets/svg/icon-talent.svg';
import { ReactComponent as IconLocate } from 'assets/svg/icon-locate.svg';

import { ROUTES } from 'constants/routes';

export interface UserIconProps {
  getIcon: (name: string) => IIcon | null;
  icons: Array<IIcon>;
}

export const ICON_NAMES = {
  HOME: 'home',
  CONTACT: 'contact',
  FAQ: 'faq',
  MODEL: 'model',
  LOGOUT: 'logout',
  SETTINGS: 'settings',
  TOOL: 'tool',
  RELATIONS: 'relations',
  RESOURCES: 'resources',
  PROFILE: 'profile',
  PROFILE_SVG: 'profile-svg',
  MORE: 'more',
  UNKNOWN_PROFILE: 'unknown-profile',
  NOTIFICATION: 'notification',
  SHOP: 'shop',
  MESSAGE_SENT: 'message-sent',
  MESSAGE_IN_PROGRESS: 'message-in-progress',
  RELATION_APPOINTMENT: 'relation-appointment',
  RELATION_MNGT: 'relation-mngt',
  PROFILE_BADGE_CHECKED: 'profile-badge-checked',
  PROFILE_BADGE_QUESTION: 'profile-badge-question',
  PROFILE_BADGE_WARNING: 'profile-badge-warning',
  PROFILE_UNSPECIFIED: 'profile-unspecified',
  CHEVRON: 'chevron',
  CHEVRON_BACK: 'chevron-back',
  RELATION_INTERACTION: 'relation-interaction',
  RELATION_SUBSCRIPTION_MARKER: 'relation-subscription-marker',
  FAVORITE: 'favorite',
  MY_PROFILE: 'my-profile',
  MY_SUMMARY: 'my-summary',
  MY_MOTIVATION: 'my-motivation',
  COLOUR_PROFILE: 'colour-profile',
  REFRESH: 'refresh',
  LOGO: 'logo',
  SIX_COLORS: 'six-colors',
  TESTIMONIALS: 'TESTIMONIALS',
  FRANCK_JULIEN: 'franck-julien',
  SHARE: 'share',
  BOOKS: 'books',
  MODULES: 'modules',
  TRAINING: 'training',
  VIDEO: 'video',
  PHONE: 'phone',
  SEND: 'send',
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  TIKTOK: 'tiktok',
  LINKEDIN: 'linkedin',
  ADD: 'add',
  MAP_DRAW: 'map-draw',
  ELEARNING: 'elearning',
  MICROPHONE: 'microphone',
  APPLE_PODCAST: 'apple-podcast',
  SPOTIFY_PODCAST: 'spotify-podcast',
  DEEZER_PODCAST: 'deezer-podcast',
  INFO: 'info',
  TALENT: 'talent',
  LOCATE: 'geolocate',
};

export interface IIcon {
  name: string;
  label: string;
  labelKey: string;
  icon: any;
  route?: string;
  hrefs?: Array<string>;
  disabled?: boolean;
}

const ICONS: Array<IIcon> = [
  {
    name: ICON_NAMES.CONTACT,
    label: '',
    labelKey: 'menu.contact',
    icon: <IconContact />,
    route: ROUTES.CONTACT,
    hrefs: [ROUTES.CONTACT],
  },
  {
    name: ICON_NAMES.FAQ,
    label: '',
    labelKey: 'menu.faq',
    icon: <IconFaq />,
    route: ROUTES.FAQ,
    hrefs: [ROUTES.FAQ],
  },
  {
    name: ICON_NAMES.MODEL,
    label: '',
    labelKey: 'menu.model',
    icon: <IconModel />,
    route: ROUTES.MODEL.COMCOLORS,
    hrefs: [ROUTES.MODEL.INDEX, ROUTES.MODEL.COMCOLORS],
  },
  {
    name: ICON_NAMES.LOGOUT,
    label: '',
    labelKey: 'common.logout',
    icon: <IconPower />,
  },
  {
    name: ICON_NAMES.SETTINGS,
    label: '',
    labelKey: 'menu.settings',
    icon: <IconSettings />,
    route: ROUTES.SETTINGS,
  },
  {
    name: ICON_NAMES.TOOL,
    label: '',
    labelKey: 'menu.tools',
    icon: <IconTool />,
    route: ROUTES.TRAINING,
  },
  {
    name: ICON_NAMES.HOME,
    label: '',
    labelKey: 'common.home',
    icon: <IconHome />,
    route: ROUTES.HOME,
    hrefs: [ROUTES.HOME],
  },
  {
    name: ICON_NAMES.RELATIONS,
    label: '',
    labelKey: 'common.relations',
    icon: <IconRelations />,
    route: ROUTES.RELATIONS.INDEX,
    hrefs: [
      ROUTES.RELATIONS.INDEX,
      ROUTES.RELATIONS.ADD,
      ROUTES.RELATIONS.IDENTIFY,
      ROUTES.RELATIONS.MANAGE,
    ],
  },
  {
    name: ICON_NAMES.RESOURCES,
    label: '',
    labelKey: 'common.resources',
    icon: <IconResources />,
    route: ROUTES.RESOURCES.INDEX,
    hrefs: [
      ROUTES.RESOURCES.INDEX,
      ROUTES.RESOURCES.MODULES,
      ROUTES.RESOURCES.BOOKS,
      ROUTES.RESOURCES.TRAINING,
    ],
  },
  {
    name: ICON_NAMES.MORE,
    label: '',
    labelKey: 'common.more',
    icon: <IconMore />,
    hrefs: [
      ROUTES.MODEL.INDEX,
      ROUTES.TRAINING,
      ROUTES.CONTACT,
      ROUTES.FAQ,
      ROUTES.SETTINGS,
      ROUTES.ELEARNING,
      ROUTES.FIND_TRAINER
    ],
  },
  {
    name: ICON_NAMES.PROFILE,
    label: '',
    labelKey: 'common.profile',
    icon: <IconProfile />,
    route: ROUTES.PROFILE.INDEX,
    hrefs: [
      ROUTES.PROFILE.INDEX,
      ROUTES.PROFILE.MY_PROFILE,
      ROUTES.PROFILE.MY_SUMMARY,
      ROUTES.PROFILE.MY_MOTIVATION,
      ROUTES.PROFILE.COLOUR_PROFILE,
    ],
  },
  {
    name: ICON_NAMES.PROFILE_SVG,
    label: '',
    labelKey: 'common.profile',
    icon: <IconProfileSvg />,
  },
  {
    name: ICON_NAMES.UNKNOWN_PROFILE,
    label: '',
    labelKey: 'common.profile',
    icon: <IconUnknownProfile />,
  },
  {
    name: ICON_NAMES.NOTIFICATION,
    label: '',
    labelKey: 'common.profile',
    icon: <IconNotification />,
    hrefs: [],
  },
  {
    name: ICON_NAMES.SHOP,
    label: '',
    labelKey: 'common.name',
    icon: <IconShop />,
    hrefs: [],
  },
  {
    name: ICON_NAMES.MESSAGE_SENT,
    label: '',
    labelKey: 'common.name',
    icon: <IconMessageSent />,
  },
  {
    name: ICON_NAMES.MESSAGE_IN_PROGRESS,
    label: '',
    labelKey: 'common.name',
    icon: <IconMessageInProgress />,
  },
  {
    name: ICON_NAMES.RELATION_APPOINTMENT,
    label: '',
    labelKey: 'common.name',
    icon: <IconRelationAppointment />,
  },
  {
    name: ICON_NAMES.RELATION_MNGT,
    label: '',
    labelKey: 'common.name',
    icon: <IconRelationMngt />,
  },
  {
    name: ICON_NAMES.PROFILE_BADGE_CHECKED,
    label: '',
    labelKey: 'common.name',
    icon: <IconProfileBadgeChecked />,
  },
  {
    name: ICON_NAMES.PROFILE_BADGE_QUESTION,
    label: '',
    labelKey: 'common.name',
    icon: <IconProfileBadgeQuestion />,
  },
  {
    name: ICON_NAMES.PROFILE_BADGE_WARNING,
    label: '',
    labelKey: 'common.name',
    icon: <IconProfileBadgeWarning />,
  },
  {
    name: ICON_NAMES.PROFILE_UNSPECIFIED,
    label: '',
    labelKey: 'common.name',
    icon: <IconProfileUnspecified />,
  },
  {
    name: ICON_NAMES.CHEVRON,
    label: '',
    labelKey: 'common.name',
    icon: <IconChevronSvg />,
  },
  {
    name: ICON_NAMES.CHEVRON_BACK,
    label: '',
    labelKey: 'common.name',
    icon: <IconChevronBackSvg />,
  },
  {
    name: ICON_NAMES.RELATION_INTERACTION,
    label: '',
    labelKey: 'common.name',
    icon: <IconRelationInteractionSvg />,
  },
  {
    name: ICON_NAMES.RELATION_SUBSCRIPTION_MARKER,
    label: '',
    labelKey: 'common.name',
    icon: <IconRelationSubscriptionMarker />,
  },
  {
    name: ICON_NAMES.FAVORITE,
    label: '',
    labelKey: 'common.name',
    icon: <IconFavorite />,
  },
  {
    name: ICON_NAMES.MY_PROFILE,
    label: '',
    labelKey: 'menu.myprofile',
    icon: <IconMyProfile />,
    route: ROUTES.PROFILE.MY_PROFILE,
    hrefs: [ROUTES.PROFILE.INDEX, ROUTES.PROFILE.MY_PROFILE],
  },
  {
    name: ICON_NAMES.MY_SUMMARY,
    label: '',
    labelKey: 'menu.mysummary',
    icon: <IconMySummary />,
    route: ROUTES.PROFILE.MY_SUMMARY,
  },
  {
    name: ICON_NAMES.MY_MOTIVATION,
    label: '',
    labelKey: 'menu.mymotivation',
    icon: <IconMyMotivation />,
    route: ROUTES.PROFILE.MY_MOTIVATION,
  },
  {
    name: ICON_NAMES.COLOUR_PROFILE,
    label: '',
    labelKey: 'menu.colourfilter',
    icon: <IconColourProfile />,
    route: ROUTES.PROFILE.COLOUR_PROFILE,
    // disabled: true,
  },
  {
    name: ICON_NAMES.REFRESH,
    label: '',
    labelKey: 'common.name',
    icon: <IconRefresh />,
  },
  {
    name: ICON_NAMES.LOGO,
    label: '',
    labelKey: 'menu.comcolors',
    icon: <IconLogo />,
    route: ROUTES.MODEL.COMCOLORS,
    hrefs: [ROUTES.MODEL.INDEX, ROUTES.MODEL.COMCOLORS],
  },
  {
    name: ICON_NAMES.SIX_COLORS,
    label: '',
    labelKey: 'menu.six_colors',
    icon: <IconSixColors />,
    route: ROUTES.MODEL.SIX_COLORS,
  },
  {
    name: ICON_NAMES.TESTIMONIALS,
    label: '',
    labelKey: 'menu.testimonials',
    icon: <IconTestimonials />,
    route: ROUTES.MODEL.TESTIMONIALS.INDEX,
  },
  {
    name: ICON_NAMES.FRANCK_JULIEN,
    label: '',
    labelKey: 'menu.franck_julien',
    icon: <IconFranckJulien />,
    route: ROUTES.MODEL.FRANCK_JULIEN,
  },
  {
    name: ICON_NAMES.VIDEO,
    label: '',
    labelKey: 'common.video',
    icon: <IconVideo />,
  },
  {
    name: ICON_NAMES.SHARE,
    label: '',
    labelKey: 'common.name',
    icon: <IconShare />,
  },
  {
    name: ICON_NAMES.MODULES,
    label: '',
    labelKey: 'menu.modules',
    icon: <IconModules />,
    route: ROUTES.RESOURCES.MODULES,
    hrefs: [ROUTES.RESOURCES.INDEX, ROUTES.RESOURCES.MODULES],
  },
  {
    name: ICON_NAMES.BOOKS,
    label: '',
    labelKey: 'menu.books',
    icon: <IconBooks />,
    route: ROUTES.RESOURCES.BOOKS,
  },
  {
    name: ICON_NAMES.TRAINING,
    label: '',
    labelKey: 'menu.training',
    icon: <IconSuccess />,
    route: ROUTES.RESOURCES.TRAINING,
  },
  {
    name: ICON_NAMES.PHONE,
    label: '',
    labelKey: 'common.phone',
    icon: <IconPhone />,
  },
  {
    name: ICON_NAMES.SEND,
    label: '',
    labelKey: 'common.send',
    icon: <IconSend />,
  },
  {
    name: ICON_NAMES.YOUTUBE,
    label: '',
    labelKey: 'common.youtube',
    icon: <IconYoutube />,
  },
  {
    name: ICON_NAMES.VIMEO,
    label: '',
    labelKey: 'common.vimeo',
    icon: <IconVimeo />,
  },
  {
    name: ICON_NAMES.TIKTOK,
    label: '',
    labelKey: 'common.tiktok',
    icon: <IconTiktok />,
  },
  {
    name: ICON_NAMES.LINKEDIN,
    label: '',
    labelKey: 'common.linkedin',
    icon: <IconLinkedin />,
  },
  {
    name: ICON_NAMES.ADD,
    label: '',
    labelKey: 'common.add',
    icon: <IconAdd />,
  },
  {
    name: ICON_NAMES.MAP_DRAW,
    label: '',
    labelKey: 'common.name',
    icon: <IconMapDraw />,
  },
  {
    name: ICON_NAMES.ELEARNING,
    label: '',
    labelKey: 'menu.elearning',
    icon: <IconElearning />,
    route: ROUTES.ELEARNING,
  },
  {
    name: ICON_NAMES.MICROPHONE,
    label: '',
    labelKey: '',
    icon: <IconMicrophone />,
  },
  {
    name: ICON_NAMES.APPLE_PODCAST,
    label: '',
    labelKey: '',
    icon: <IconApplePodcast />,
  },
  {
    name: ICON_NAMES.SPOTIFY_PODCAST,
    label: '',
    labelKey: '',
    icon: <IconSpotifyPodcast />,
  },
  {
    name: ICON_NAMES.DEEZER_PODCAST,
    label: '',
    labelKey: '',
    icon: <IconDeezerPodcast />,
  },
  {
    name: ICON_NAMES.INFO,
    label: '',
    labelKey: '',
    icon: <IconInfo />,
  },
  {
    name: ICON_NAMES.TALENT,
    label: '',
    labelKey: '',
    icon: <IconTalent />,
  },
  {
    name: ICON_NAMES.LOCATE,
    label: 'Locate',
    route: ROUTES.FIND_TRAINER,
    labelKey: 'menu.findtrainer',
    icon: <IconLocate />,
  },
];

function useIcons(iconName: Array<string> = []): UserIconProps {
  const { t } = useTranslation('translation');
  const labelSetter = (icon: IIcon): IIcon => {
    return {
      ...icon,
      label: t(icon.labelKey),
    };
  };
  const icons: Array<IIcon> = iconName.reduce((accu, item) => {
    const iconFound = ICONS.find((i) => i.name === item);

    iconFound && accu.push(labelSetter(iconFound));
    return accu;
  }, [] as Array<IIcon>);
  const getIcon = (name: string): IIcon | null => {
    const iconObj = ICONS.find((i) => i.name === name);

    return (iconObj && labelSetter(iconObj)) || null;
  };

  return { getIcon, icons };
}

export default useIcons;

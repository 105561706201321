import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { PageTitle } from 'layout/page/Page.layout.styled';
import { SUBTITLE } from 'styles/helpers/typography';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { BREAKPOINTS } from 'styles/constants/devices';

const QuestionAnswerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .limits-container {
    .min,
    .max {
      width: ${prem(60)};
    }
  }

  .the-id {
    color: red;
  }
`;

const QuestionSlideWrapperEl = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 ${prem(25)};
  justify-content: center;

  ${PageTitle} {
    margin-bottom: ${prem(46)};
  }

  p.question {
    ${SUBTITLE.small};
    color: var(--ion-text-color-secondary);
    margin-bottom: ${prem(60)};
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${prem(80)} 0 0;

    ${IonButtonWrapper} {
      width: ${prem(250)};
    }

    @media (${BREAKPOINTS.M}) {
      flex-direction: row-reverse;
      gap: ${prem(16)};
    }

    &.no-response {
      align-items: start;
      margin: 0;
    }
  }

  &.end {
    justify-content: center;
  }
`;

const QuestionTransitionWrapper = styled.div`
  ${PageTitle} {
    margin-bottom: ${prem(100)};

    &.transition-label {
      text-align: center;
    }
  }
`;

export { QuestionAnswerWrapper, QuestionSlideWrapperEl, QuestionTransitionWrapper };

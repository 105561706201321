import { IntroContainer } from 'pages/intro/Intro.styled';
import styled from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { IonInputWrapper, IonItemWrapper } from 'components/common/form/textfield/Textfield.styled';
import { IonButtonWrapper } from 'components/common/button/Button.styled';
import { MQ_MAX_HEIGHT } from 'styles/constants/devices';

const LoginFormWrapper = styled(IntroContainer)`
  text-align: center;

  form {
    display: flex;
    flex-direction: column;
    gap: ${prem(40)};
  }

  ${IonInputWrapper} {
    ${IonItemWrapper} {
      ion-label {
        color: white !important;
      }

      ion-input {
        color: white;
      }

      &::part(native) {
        background: var(--comcolors-modal-background);
      }
    }

    @media (${MQ_MAX_HEIGHT}) {
      margin-bottom: ${prem(8)};
    }
  }

  ${IonButtonWrapper} {
    margin: 0 auto;
    width: 50%;
    min-width: ${prem(190)};
    max-width: ${prem(250)};

    @media (${MQ_MAX_HEIGHT}) {
      margin: ${prem(24)} auto;
    }
  }

  .no-account {
    cursor: pointer;
  }
`;

export { LoginFormWrapper };

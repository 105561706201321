import styled from 'styled-components';
import { Container } from 'layout/page/Page.layout.styled';
import { prem } from 'styles/helpers/spacing';
import { SelectBoxWrapper } from 'components/common/form/selectbox/SelectBox.styled';
import { RegularButtonFlexed } from 'components/common/button/Button.styled';

const QuestionnaireMainWrapper = styled.div`
  height: 100%;

  ${Container} {
    height: 100%;
  }
`;

const QuestionnaireFormIntro = styled.p`
  margin-bottom: ${prem(32)};
`;

const QuestionnaireSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    color: var(--ion-text-color-secondary);
  }

  .intro-form {
    width: ${prem(400)};
    max-width: 80%;
    margin: 0 auto;
  }

  .submit-button {
    margin-top: ${prem(80)};
  }

  ${SelectBoxWrapper} {
    margin-bottom: ${prem(20)};

    ${RegularButtonFlexed} {
      span {
        text-align: start;
        width: 100%;
      }
    }

    select {
      padding-right: ${prem(30)};
    }
  }
`;

const QuestionnaireContent = styled.div`
  flex-grow: 1;
  max-height: 90vh;
  overflow: auto;

  .questionnaire-description {
    margin-bottom: ${prem(40)};
    color: var(--ion-text-color-secondary);

    img {
      user-select: none;
      width: 100%;
      max-width: ${prem(650)};
      display: block;
      margin: 0 auto;
      cursor: normal;
    }
  }
`;

const QuestionnaireFooter = styled.div`
  flex-basis: ${prem(50)};
`;

export {
  QuestionnaireMainWrapper,
  QuestionnaireSlideWrapper,
  QuestionnaireContent,
  QuestionnaireFooter,
  QuestionnaireFormIntro,
};

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'layout/page/Page.layout.styled';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import {
  QuestionnaireContent,
  QuestionnaireFormIntro,
  QuestionnaireSlideWrapper,
} from 'pages/questionnaire/Questionnaire.styled';
import { FormFieldsGroup } from 'components/common/form/Form.styled';
import SelectBox, { SelectBoxProps } from 'components/common/form/selectbox/SelectBox';
import { Form, IActivities } from 'types/app';
import useFormHandler from 'hooks/useFormHandler';
import useAPIQuestionnaire from 'hooks/api/useAPIQuestionnaire';
import { getLocaleValue } from 'helpers/utils';
import { useSwiper } from 'swiper/react';

export interface IQuestionnaireProfileForm {
  activities: IActivities;
  currentActivity?: string;
  currentBirthYear?: string;
}

const getYearsList = (min: number, max: number): Array<Form.IOption> => {
  const yearsRange = Array.from({ length: max - min + 1 }, (_, index) => max - index);
  return yearsRange.map((year, index) => ({
    id: index,
    label: year.toString(),
    value: year.toString(),
  }));
};

const YEAR_MIN = 1920;
const YEAR_MAX = new Date().getFullYear() - 15;

const QuestionnaireProfileForm: React.FC<IQuestionnaireProfileForm> = ({
  activities,
  currentActivity,
  currentBirthYear,
}) => {
  const { i18n, t } = useTranslation('translation');
  const formEl = useRef<HTMLFormElement | null>(null);
  const parentSwiper = useSwiper();
  const { updateUserInformation } = useAPIQuestionnaire();
  const yearsList = getYearsList(YEAR_MIN, YEAR_MAX);

  const getYearFromList = (yearValue: string) => {
    return yearsList.find((year) => {
      return year.value == yearValue;
    });
  };

  const getActivityFromList = (activityToSearch: string) => {
    const matchedActivity = activities.find(
      (activity) => activity.code[i18n.language] === activityToSearch,
    );
    if (matchedActivity) {
      const { code, label } = matchedActivity;
      const index = activities.findIndex((activity) => activity === matchedActivity);
      return { id: index, label: label[i18n.language], value: code[i18n.language] };
    }
    return null;
  };

  const formFields: Array<Form.IField> = [
    {
      name: 'birth_year',
      options: yearsList,
      value: getYearFromList(currentBirthYear as string),
      required: false,
    },
    {
      name: 'activity',
      options: activities
        ? activities.map((activity, index) => ({
            id: index,
            label: getLocaleValue(activity.label, i18n.language),
            value: getLocaleValue(activity.code, i18n.language),
          }))
        : [],
      value: getActivityFromList(currentActivity as string) as Form.IValue,
      required: false,
    },
  ];
  const { fields, getFieldByName, updateFieldValue, updateFields } = useFormHandler(formFields);

  const formatFieldsPayload = () => {
    return {
      birth_year: (getFieldByName('birth_year').value as Form.IOption)?.value || '',
      activity: (getFieldByName('activity').value as Form.IOption)?.value || '',
    };
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const payload = formatFieldsPayload();

    if (payload.activity || payload.birth_year) {
      await updateUserInformation(payload.birth_year, `${payload.activity}`);
    }

    setTimeout(() => {
      parentSwiper.slideNext();
    }, 250);
  };

  useEffect(() => {
    formEl.current && (formEl.current as HTMLFormElement).reset();
    updateFields(formFields);
  }, [i18n.language]);

  return (
    <QuestionnaireSlideWrapper>
      <QuestionnaireContent className="content">
        <PageTitle>{t('questionnaire.before-start')}</PageTitle>
        <QuestionnaireFormIntro>{t('questionnaire.before-start-intro')}</QuestionnaireFormIntro>
        <div className="intro-form">
          <form noValidate onSubmit={onSubmit} autoComplete="off" ref={formEl}>
            {activities && (
              <FormFieldsGroup>
                <SelectBox
                  {...({
                    ...getFieldByName('birth_year', fields),
                    label: t('common.year-of-birth'),
                    isNative: true,
                    onChange: (value?) => updateFieldValue('birth_year', value),
                  } as SelectBoxProps)}
                />
                <SelectBox
                  {...({
                    ...getFieldByName('activity', fields),
                    label: t('common.activity'),
                    onChange: (value?) => updateFieldValue('activity', value),
                    isNative: true,
                  } as SelectBoxProps)}
                  name="Selectbox"
                />
                <GenericDiv
                  className="submit-button"
                  minWidth={prem(100)}
                  maxWidth={prem(150)}
                  center
                >
                  <Button type="submit" fill="outline">
                    <span>{t('common.validate')}</span>
                  </Button>
                </GenericDiv>
              </FormFieldsGroup>
            )}
          </form>
        </div>
      </QuestionnaireContent>
    </QuestionnaireSlideWrapper>
  );
};

export default QuestionnaireProfileForm;

import React, { useContext, useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import {
  AddGroupWrapper,
  RelationGroupsList,
  RelationGroupsWrapper,
} from 'components/relations/group/RelationGroup.styled';
import { IRelation, IRelationGroup } from 'types/app';
import RelationGroupItem from 'components/relations/group/RelationGroupItem';
import { ButtonIconWrapper, RegularButtonFlexed } from 'components/common/button/Button.styled';
import { useTranslation } from 'react-i18next';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import RelationGroupEdit from 'components/relations/group/relation-group-edit/RelationGroupEdit';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import AppModalMessage from 'components/modal/AppModalMessage';
import useAPIRelations from 'hooks/api/useAPIRelations';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import { ROUTES } from 'constants/routes';
import { useQuery } from 'hooks/useQuery';
import { ReactComponent as IconAddRelation } from 'assets/svg/icon-people-plus.svg';
import { ReactComponent as IconAddGroup } from 'assets/svg/icon-add-group.svg';

export interface RelationGroupsProps {
  groups?: Array<IRelationGroup>;
}

const RelationGroups: React.FC<RelationGroupsProps> = ({ groups }) => {
  const { t } = useTranslation('translation');
  const ionRouter = useIonRouter();
  const { removeGroup, addRelationToGroup, removeRelation } = useAPIRelations();
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { user } = useContext(UserContext) as UserContextProps;
  const { setRelationQuestionnaireFactory } = useContext(RelationsContext) as RelationsContextProps;
  const query = useQuery();
  const openedGroupId = parseInt(query.get('open') || '', 10);
  const [currentOpened, setCurrentOpened] = useState<IRelationGroup | undefined>(
    openedGroupId
      ? {
          id: openedGroupId,
          name: '',
        }
      : undefined,
  );

  // Add group
  const onAddGroup = () => {
    updateModalConf({
      component: (
        <RelationGroupEdit
          key={Math.random()}
          title={t('relations.addgroup')}
          group={{ id: -1, name: '' }}
          userId={user?.id}
          onValidate={updateModalConf}
        />
      ),
    });
  };

  // Change group name
  const onEditGroup = (group: IRelationGroup) => {
    updateModalConf({
      component: (
        <RelationGroupEdit
          key={Math.random()}
          title={t('relations.renamegroup')}
          group={group}
          userId={user?.id}
          onValidate={updateModalConf}
        />
      ),
    });
  };

  // Remove group
  const remove = (groupId: number) => {
    removeGroup(groupId);
    updateModalConf();
  };

  // Confirm before remove group
  const onRemoveGroup = (group: IRelationGroup) => {
    updateModalConf({
      component: (
        <AppModalMessage
          key={Math.random()}
          message={t('relations.removeconfirm', { group: group.name })}
          onValidate={() => {
            remove(group.id);
          }}
          onDismiss={updateModalConf}
        />
      ),
    });
  };

  // Handle one group opened only
  const onOpenGroup = (group: IRelationGroup) => {
    setCurrentOpened(currentOpened?.id === group.id ? undefined : group);
  };

  // Add a relation into a group
  const addRelationIntoGroup = (relation: IRelation, group: IRelationGroup) => {
    updateModalConf({
      component: (
        <AppModalMessage
          key={Math.random()}
          message={t('relations.addintogroupconfirm', {
            relationName: relation.fullName,
            group: group.name,
          })}
          onValidate={() => {
            addRelationToGroup(relation.id, group.id);
            updateModalConf();
          }}
          onDismiss={updateModalConf}
        />
      ),
    });
  };

  // Confirm before remove relation in a group
  const onRemoveRelation = (relation: IRelation, groupId: number) => {
    updateModalConf({
      component: (
        <AppModalMessage
          key={Math.random()}
          message={t('relations.removerelationconfirm', {
            relationName: relation.fullName,
            group: name,
          })}
          onValidate={() => {
            removeRelation(relation.id, groupId);
            updateModalConf();
          }}
          onDismiss={updateModalConf}
        />
      ),
    });
  };

  // When a link to identify a relation clicked
  const onIdentifyRelation = (relation: IRelation) => {
    setRelationQuestionnaireFactory();
    ionRouter.push(`${ROUTES.RELATIONS.IDENTIFY}/${relation.id}`);
  };

  // When a link to manage relation clicked
  const onManageRelation = (relation: IRelation) => {
    ionRouter.push(`${ROUTES.RELATIONS.MANAGE}/${relation.id}`);
  };

  // When user choose a rendez-vous
  const onAppointment = (relation: IRelation) => {
    ionRouter.push(`${ROUTES.RELATIONS.APPOINTMENT}/${relation.id}`);
  };

  const onAddRelation = () => {
    ionRouter.push(ROUTES.RELATIONS.ADD);
  };

  useEffect(() => {
    openedGroupId &&
      setCurrentOpened({
        id: openedGroupId,
        name: '',
      });
  }, [openedGroupId]);

  return (
    <RelationGroupsWrapper>
      <div className="add-relation">
        <RegularButtonFlexed onClick={onAddRelation}>
          <ButtonIconWrapper>
            <IconAddRelation />
          </ButtonIconWrapper>
          <span>{t('relations.addrelation.title')}</span>
        </RegularButtonFlexed>
      </div>
      <AddGroupWrapper>
        <RegularButtonFlexed onClick={onAddGroup}>
          <ButtonIconWrapper>
            <IconAddGroup />
          </ButtonIconWrapper>
          <span>{t('relations.addgroup')}</span>
        </RegularButtonFlexed>
      </AddGroupWrapper>
      <RelationGroupsList>
        {!!groups?.length &&
          groups.map((group: IRelationGroup, index: number) => {
            return (
              <RelationGroupItem
                {...group}
                key={`${group.name}-${index}`}
                onEdit={() => {
                  onEditGroup(group);
                }}
                onRemove={() => {
                  onRemoveGroup(group);
                }}
                onOpened={() => {
                  onOpenGroup(group);
                }}
                onAddRelationIntoGroup={(relation: IRelation, group: IRelationGroup) => {
                  addRelationIntoGroup(relation, group);
                }}
                onRemoveRelation={(relation: IRelation) => {
                  onRemoveRelation(relation, group.id);
                }}
                onIdentifyRelation={(relation: IRelation) => {
                  onIdentifyRelation(relation);
                }}
                onManageRelation={(relation: IRelation) => {
                  onManageRelation(relation);
                }}
                onMakeAppointmentToRelation={onAppointment}
                isOpened={currentOpened?.id === group.id}
              />
            );
          })}
      </RelationGroupsList>
    </RelationGroupsWrapper>
  );
};

export default RelationGroups;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QuestionnaireMainProgressWrapper,
  QuestionnaireMainSliderWrapper,
  QuestionnaireMainWrapper,
} from 'pages/questionnaire/slides/questionnaire/QuestionnaireMain.styled';
import ProgressBar from 'components/common/progress-bar/ProgressBar';
import { SwiperSlide, useSwiper } from 'swiper/react';
import Slider from 'components/slider/Slider';
import QuestionSlideWrapper, {
  QuestionSlideWrapperProps,
} from 'pages/questionnaire/slides/questionnaire/questionnaire-slide/QuestionSlideWrapper';
import { Swiper as SwiperClass } from 'swiper/types';
import useAPIQuestionnaire, { IResponse } from 'hooks/api/useAPIQuestionnaire';
import { Questionnaire } from 'types/app';

export interface QuestionnaireMainProps {
  q: Questionnaire.IQuestionnaire;
  topicNumbers: number;
}

const QuestionnaireMain: React.FC<QuestionnaireMainProps> = ({ q, topicNumbers }) => {
  const { t } = useTranslation('translation');
  const parentSwiper = useSwiper();
  const { sendResponses } = useAPIQuestionnaire();
  const [progression, updateProgression] = useState(0);
  const questionnaire = useRef<Questionnaire.IQuestionnaire>(q);
  const swiperInstance = useRef<SwiperClass>();
  const [slidesContents, setSlidesContents] = useState<Array<QuestionSlideWrapperProps>>([]);

  const updateSlidesContents = () => {
    const slideIndex = swiperInstance.current?.realIndex;
    const theQuestionnaire = questionnaire.current;
    const nextQuestionIndex = theQuestionnaire.currentQuestionIndex + 1;
    const prevQuestionIndex = theQuestionnaire.currentQuestionIndex - 1;
    const currentQuestion = theQuestionnaire.questions[theQuestionnaire.currentQuestionIndex];
    const nextQuestion = theQuestionnaire.questions[nextQuestionIndex];
    const previousQuestion = theQuestionnaire.questions[prevQuestionIndex];

    const slideCurrent: QuestionSlideWrapperProps = {
      question: currentQuestion,
      gotoNext:
        (nextQuestion?.response && nextQuestion.id > 0) || currentQuestion?.response
          ? gotoNext
          : undefined,
      gotoPrevious: previousQuestion?.response ? gotoPrevious : undefined,
      onAnswer,
    };
    const slideNext: QuestionSlideWrapperProps = {
      question: nextQuestion,
      gotoNext:
        theQuestionnaire.questions[nextQuestionIndex + 1]?.response || nextQuestion?.response
          ? gotoNext
          : undefined,
      gotoPrevious,
    };
    const slidePrevious: QuestionSlideWrapperProps = {
      question: previousQuestion,
      gotoNext,
      gotoPrevious: theQuestionnaire.questions[prevQuestionIndex - 1] ? gotoNext : undefined,
    };

    /*
    console.log('===============');
    console.log('slideCurrent: ', slideCurrent);
    console.log('slideNext: ', slideNext);
    console.log('slidePrevious: ', slidePrevious);
    */

    if (slideIndex !== undefined && swiperInstance.current) {
      const totalSlides = swiperInstance.current.slides.length;
      const currentSlideIndex = slideIndex;
      const nextSlideIndex = slideIndex + 1 >= totalSlides ? 0 : slideIndex + 1;
      const prevSlideIndex = slideIndex - 1 < 0 ? totalSlides - 1 : slideIndex - 1;
      const contents: Array<QuestionSlideWrapperProps> = [];

      contents[currentSlideIndex] = slideCurrent;
      contents[nextSlideIndex] = slideNext;
      contents[prevSlideIndex] = slidePrevious;

      /*
      console.log('currentSlideIndex: ', currentSlideIndex);
      console.log('nextSlideIndex: ', nextSlideIndex);
      console.log('prevSlideIndex: ', prevSlideIndex);
      console.log('===============');
      */
      setSlidesContents(contents);
    }
  };
  const onInitSwiper = (swiper: SwiperClass) => {
    swiperInstance.current = swiper;
    onSwiperSlideChange();
  };
  const onSwiperSlideChange = () => {
    updateSlidesContents();
  };
  const gotoPrevious = () => {
    const prevIndex = questionnaire.current?.currentQuestionIndex - 1;
    questionnaire.current.currentQuestionIndex = prevIndex > 0 ? prevIndex : 0;
    swiperInstance.current?.slidePrev();
  };
  const gotoNext = () => {
    const nextIndex = questionnaire.current?.currentQuestionIndex + 1;
    questionnaire.current.currentQuestionIndex = questionnaire.current.questions[nextIndex]
      ? nextIndex
      : questionnaire.current.questions.length - 1;
    swiperInstance.current?.slideNext();
  };
  const updateQuestionnaire = (qAnswered: Questionnaire.IQuestion) => {
    const tmpQnnaire: Questionnaire.IQuestionnaire = { ...questionnaire.current };
    const questions = [...tmpQnnaire.questions];

    questions.forEach((q) => {
      if (q.id === qAnswered.id) {
        q.response = qAnswered.response;
      }
    });

    const nbQAnswered = questions.filter((item) => !!item.response).length;
    tmpQnnaire.progression = Math.floor(
      (nbQAnswered / (questions.length - topicNumbers || 0)) * 100,
    );

    questionnaire.current = tmpQnnaire;
    updateProgression(tmpQnnaire.progression);
  };

  const onAnswer = async (qAnswered: Questionnaire.IQuestion) => {
    const isSent = await sendResponses([
      {
        questionId: qAnswered.id,
        questionnaireId: questionnaire.current.id,
        response: qAnswered.response,
        responseOrder: qAnswered.order,
      },
    ] as Array<IResponse>);

    if (isSent) {
      updateQuestionnaire(qAnswered);
      if (questionnaire.current.progression === 100) {
        parentSwiper.slideNext();
      } else {
        gotoNext();
      }
    }
  };

  // Load
  useEffect(() => {
    updateProgression(questionnaire.current.progression);
    updateSlidesContents();
  }, []);
  return (
    <QuestionnaireMainWrapper>
      <QuestionnaireMainSliderWrapper>
        <Slider
          initialSlide={1}
          onInit={onInitSwiper}
          allowTouchMove={false}
          onSlideChangeTransitionEnd={onSwiperSlideChange}
          loop
        >
          <SwiperSlide>
            <QuestionSlideWrapper
              {...slidesContents[0]}
              key={slidesContents[0]?.question ? `${slidesContents[0].question.id}` : 0}
            />
          </SwiperSlide>
          <SwiperSlide>
            <QuestionSlideWrapper
              {...slidesContents[1]}
              key={slidesContents[1]?.question ? `${slidesContents[1].question.id}` : 1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <QuestionSlideWrapper
              {...slidesContents[2]}
              key={slidesContents[2]?.question ? `${slidesContents[2].question.id}` : 2}
            />
          </SwiperSlide>
        </Slider>
      </QuestionnaireMainSliderWrapper>
      {progression < 100 && (
        <QuestionnaireMainProgressWrapper>
          <ProgressBar percent={progression} title={`${t('questionnaire.progression-title')}`} />
        </QuestionnaireMainProgressWrapper>
      )}
    </QuestionnaireMainWrapper>
  );
};

export default QuestionnaireMain;

import React, { useRef } from 'react';
import {
  QuestionAnswerWrapper,
  QuestionSlideWrapperEl,
  QuestionTransitionWrapper,
} from 'pages/questionnaire/slides/questionnaire/questionnaire-slide/QuestionSlideWapper.styled';
import { Questionnaire } from 'types/app';
import { PageTitle } from 'layout/page/Page.layout.styled';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import QuestionnaireResponses, {
  IQResponse,
} from 'components/questionnaire/QuestionnaireResponses';
import Button from 'components/common/button/Button';

export interface QuestionSlideWrapperProps {
  question?: Questionnaire.IQuestion;
  gotoPrevious?: () => void;
  gotoNext?: () => void;
  onAnswer?: (questionWithResponse: Questionnaire.IQuestion) => void;
}

const getResponsesChoices = (min: number, max: number): Array<IQResponse> => {
  const size = max - min + 1;
  return Array.from(Array(size).keys()).map((i, index) => {
    return {
      label: `${min + index}`,
      value: min + index,
    };
  });
};
const QuestionSlideWrapper: React.FC<QuestionSlideWrapperProps> = ({
  question,
  gotoPrevious,
  gotoNext,
  onAnswer,
}) => {
  const { i18n, t } = useTranslation('translation');
  const setTimeoutId = useRef<number>();
  const selectedResponse = question?.response
    ? { label: `${question.response}`, value: question.response }
    : undefined;
  const limitsLabels = question?.responseMinLabel
    ? {
        min: getLocaleValue<string>(question?.responseMinLabel, i18n.language),
        max: getLocaleValue<string>(question?.responseMaxLabel, i18n.language),
      }
    : undefined;
  const onSelect = (rep?: IQResponse) => {
    setTimeoutId.current && clearTimeout(setTimeoutId.current);
    // If user answer and not the same response
    // we send response
    if (rep) {
      if (rep.value !== selectedResponse?.value) {
        setTimeoutId.current = window.setTimeout(() => {
          const q: Questionnaire.IQuestion = {
            ...question,
            response: rep.value,
          } as Questionnaire.IQuestion;
          onAnswer && onAnswer(q);
        }, 250);
      }
    }
  };

  return (
    <QuestionSlideWrapperEl>
      {question && question.id > 0 && (
        <>
          <PageTitle>{getLocaleValue(question.sectionLabel, i18n.language)}</PageTitle>
          <QuestionAnswerWrapper>
            {/* used for debugging <p className="the-id">{question.id}</p> */}
            <p className="question">{getLocaleValue(question.label, i18n.language)}</p>
            <QuestionnaireResponses
              choices={getResponsesChoices(question.responseMin, question.responseMax)}
              onSelect={onSelect}
              selected={selectedResponse}
              limitsLabels={limitsLabels}
            />
            <div className="buttons-wrapper">
              {gotoNext && (
                <Button
                  onClick={() => {
                    gotoNext();
                  }}
                  fill="outline"
                >
                  {t('common.next')}
                </Button>
              )}
              {gotoPrevious && (
                <Button
                  onClick={() => {
                    gotoPrevious();
                  }}
                  fill="outline"
                >
                  {t('common.previous')}
                </Button>
              )}
            </div>
          </QuestionAnswerWrapper>
        </>
      )}
      {question && question.id < 0 && (
        <QuestionTransitionWrapper>
          <PageTitle className="transition-label">
            {`${getLocaleValue(question.sectionLabel, i18n.language)}`}
          </PageTitle>
          <QuestionAnswerWrapper>
            <div className="buttons-wrapper no-response">
              {gotoNext && (
                <Button
                  onClick={() => {
                    gotoNext();
                  }}
                  fill="outline"
                >
                  {t('common.next')}
                </Button>
              )}
              {gotoPrevious && (
                <Button
                  onClick={() => {
                    gotoPrevious();
                  }}
                  fill="outline"
                >
                  {t('common.previous')}
                </Button>
              )}
            </div>
          </QuestionAnswerWrapper>
        </QuestionTransitionWrapper>
      )}
    </QuestionSlideWrapperEl>
  );
};

export default QuestionSlideWrapper;

import styled, { css } from 'styled-components';
import { prem } from 'styles/helpers/spacing';
import { transparentEl } from 'styles/helpers/utils';
import { BODY, SUBTITLE } from 'styles/helpers/typography';
import { FONT_WEIGHT } from 'styles/constants/fonts';
import { ButtonIconWrapper, RegularButtonFlexed } from 'components/common/button/Button.styled';
import { RelationItemWrapper } from 'components/relations/relation/RelationItem.styled';
import { RelationMenuWrapper } from 'pages/relations/Relations.styled';

const RelationGroupItemWrapper = styled.div`
  margin-bottom: ${prem(24)};
`;

const RelationGroupItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${prem(16)};
  position: relative;
`;

const RelationMenuGroupWrapper = styled(RelationMenuWrapper)`
  position: absolute;
  right: ${prem(16)};
  bottom: 0;
  z-index: 1000;
  ${({ yPos }) => css`
    transform: translateY(${yPos});
  `}
`;

const RelationGroupItemHeaderContextMenuButton = styled.button`
  ${transparentEl};
  cursor: pointer;
  color: var(--ion-color-accent);
  width: ${prem(22)};
  height: ${prem(22)};

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const MenuLabel = css`
  color: var(--ion-text-color-secondary);
  ${SUBTITLE.small};
  font-weight: ${FONT_WEIGHT.REGULAR};
`;

const RelationGroupNameContainer = styled.button`
  ${transparentEl};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${prem(20)};
  color: var(--ion-color-accent);

  span {
    ${MenuLabel};
  }
`;

const RelationGroupsWrapper = styled.div`
  padding: ${prem(24)} 0;

  .add-relation {
    display: flex;
    align-items: center;
    margin-bottom: ${prem(5)};
    padding-left: ${prem(11)};

    ${RegularButtonFlexed} {
      text-align: start;
      gap: ${prem(12)};
    }

    ${ButtonIconWrapper} {
      width: ${prem(30)};
      height: ${prem(30)};
      background-color: var(--ion-color-orange);
      padding: ${prem(5)};
      border-radius: ${prem(40)};
      position: relative;

      svg {
        width: 60%;
        height: auto;
        display: block;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    span {
      font-style: italic;
      color: var(--ion-text-color-secondary);
      ${SUBTITLE.small};
      font-weight: ${FONT_WEIGHT.REGULAR};
    }
  }
`;
const RelationGroupsList = styled.div``;

const RelationGroupRemoveEntry = styled.div`
  display: flex;
  gap: ${prem(15)};

  .block-text {
    flex: 1;

    .entry {
      display: flex;
      align-items: center;
      ${MenuLabel};

      span {
        opacity: 0.5;
        margin-right: ${prem(8)};
      }

      ${ButtonIconWrapper} {
        transform: rotate(180deg);
      }
    }

    .note {
      ${BODY.small};
      color: var(--ion-color-accent);
      max-width: ${prem(150)};
    }
  }
`;

const AddGroupWrapper = styled.div`
  ${RegularButtonFlexed} {
    color: var(--ion-color-accent);
    margin-bottom: ${prem(40)};
    text-align: start;
    padding-left: ${prem(11)};
    gap: ${prem(11)};

    span {
      font-style: italic;
      color: var(--ion-text-color-secondary);
      ${SUBTITLE.small};
      font-weight: ${FONT_WEIGHT.REGULAR};
    }

    ${ButtonIconWrapper} {
      width: ${prem(30)};
      height: ${prem(30)};
      background-color: var(--ion-color-orange);
      padding: ${prem(2)};
      border-radius: ${prem(40)};
      position: relative;

      img {
        width: ${prem(25)} !important;
        height: auto;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    
  }
`;

const RelationItemsWrapper = styled.div`
  padding: ${prem(16)} 0;

  ${RelationItemWrapper} {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export {
  RelationGroupItemWrapper,
  RelationGroupItemHeader,
  RelationGroupNameContainer,
  RelationGroupItemHeaderContextMenuButton,
  RelationGroupsWrapper,
  AddGroupWrapper,
  RelationGroupsList,
  RelationMenuGroupWrapper,
  RelationItemsWrapper,
  RelationGroupRemoveEntry,
};
